import { Error404Page } from 'maven-ui-kit/core';
import { useRouter } from 'next/router';

const NotFound = () => {
  const { push } = useRouter();

  return (
    <Error404Page
      onRedirect={() => {
        push('/');
      }}
    />
  );
};

export default NotFound;
